import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import translationsQuery from "../queries/translationsQuery";
import editAccountMutation from "src/mutations/editAccountMutation";
import webviewBridge from "./webview-bridge";
import moment from "moment";
import store from "src/store/store";
import { setUserLocaleToStore } from "src/store/actions";

type keys = "currentObjectId" | "currentObjectType" | "pageRefreshError" | "updatePasswordRedirect" | "locale" | string;

export function storeLocal(key: keys, value: string): boolean {
  if (!window.localStorage) {
    return false;
  }

  try {
    window.localStorage.setItem(key, value);

    return true;
  } catch (error) {
    return false;
  }
}

export function fetchLocal(key: keys, defaultValue?: string): string | undefined {
  if (!window.localStorage) {
    return defaultValue;
  }

  try {
    const value = window.localStorage.getItem(key);

    if (value === null) {
      return defaultValue;
    }

    return value;
  } catch (error) {
    return defaultValue;
  }
}

export function removeLocal(key: keys): boolean {
  if (!window.localStorage) {
    return false;
  }

  try {
    window.localStorage.removeItem(key);

    return true;
  } catch (error) {
    return false;
  }
}

// Exception, as this uses apollo
export async function setUserLocale(
  locale: string,
  apolloClient?: ApolloClient<InMemoryCache>,
  ChangeApiLang?: boolean
) {
  try {
    window.localStorage.setItem("locale", locale);
    store.dispatch(setUserLocaleToStore(locale));
    moment.locale(locale);

    if (webviewBridge.isNative()) {
      webviewBridge.updateLocale(locale);
    }

    if (apolloClient) {
      if (ChangeApiLang) {
        await apolloClient.mutate({
          mutation: editAccountMutation,
          variables: {
            user: {
              language: locale
            }
          }
        });
      }

      // Cannot use the reload in app because it will cause the app to loop
      if (webviewBridge.isNative()) {
        await apolloClient.query({
          query: translationsQuery,
          fetchPolicy: "network-only"
        });
      }
      // Reload the page to refresh the permissions etc.
      else {
        window.location.reload();
      }
    }
  } catch (err) {
    console.log("Setting user locale failed: ", err);
    if (err instanceof Error) {
      throw new Error(err.message);
    }
  }
}
