declare global {
  interface Window {
    WebViewBridge?: {
      send: (message: string) => void;
      onMessage?: (message: string) => void;
      onError?: (err: string) => void;
    };
  }
}

export type WebViewBridgeHandler =
  | "INIT"
  | "UPDATE_LOCALE"
  | "LOGOUT"
  | "DOWNLOAD_FILE"
  | "OPEN_LINK"
  | "NAVIGATE"
  | "REGISTER_PUSH_TOKEN"
  | "LOG"
  | "RESTART_APP";

export type NativeHandler = "INITIAL_DATA" | "UPDATE_MESSAGES" | "NOTIFICATION" | "PUSH_NOTIFICATION_PERMISSION";

export interface ResponseData {
  success: boolean;
  error?: string;
  [key: string]: any;
}

export interface Payload {
  [k: string]: any;
}

export type Handler = (data: any, responseCallback?: ResponseCallback) => void;

interface Message {
  handlerName?: string;
  data?: Payload;
  callbackId?: string;
  responseId?: string;
  responseData?: ResponseData;
}

type ResponseCallback = (args: ResponseData) => void;

interface ResponseCallbacks {
  [key: string]: ResponseCallback;
}

interface MessageHandlers {
  [key: string]: Handler;
}

export default class WebViewBridge {
  private isReady: boolean;
  private messageHandlers: MessageHandlers;
  private responseCallbacks: ResponseCallbacks;
  private UUID: number;

  constructor() {
    this.isReady = false;
    this.messageHandlers = {};
    this.responseCallbacks = {};
    this.UUID = 1;
  }

  public setup = () =>
    new Promise(resolve => {
      if (window.WebViewBridge && !window.WebViewBridge.onMessage) {
        window.WebViewBridge.onMessage = message => this.onMessage(message);
        window.WebViewBridge.onError = error => this.onError(error);
        this.isReady = true;
      }

      resolve({ isReady: this.isReady });
    });

  public registerHandler(handlerName: NativeHandler, handler: Handler) {
    this.messageHandlers[handlerName] = handler;
  }

  public request(handlerName: WebViewBridgeHandler, data: Payload = {}) {
    return new Promise((resolve, reject) => {
      this.callHandler(handlerName, data, responseData => {
        if (responseData.error) {
          reject(responseData.error);
        }

        resolve(responseData);
      });
    });
  }

  public callHandler(handlerName: WebViewBridgeHandler, data: Payload, responseCallback?: ResponseCallback) {
    this._send({ handlerName, data }, responseCallback);
  }

  private onMessage(messageString: string) {
    const message = this.parseToJSON(messageString);
    let responseCallback;

    if (message.responseId && message.responseData) {
      responseCallback = this.responseCallbacks[message.responseId];

      if (!responseCallback) {
        return;
      }
      responseCallback(message.responseData);
      delete this.responseCallbacks[message.responseId];
    } else {
      if (message.callbackId) {
        responseCallback = (responseData: ResponseData) =>
          this._send({
            handlerName: message.handlerName,
            responseId: message.callbackId,
            responseData
          });
      }

      let handler;

      if (message.handlerName) {
        handler = this.messageHandlers[message.handlerName];
      }

      if (!handler) {
        this.onError(`No handler for: ${message.handlerName}`);
      } else {
        handler(message.data, responseCallback);
      }
    }
  }

  private _send(message: Message, responseCallback?: ResponseCallback) {
    if (!window.WebViewBridge) {
      return;
    }

    if (responseCallback) {
      const callbackId = `cb_${this.UUID++}_${new Date().getTime()}`;

      this.responseCallbacks[callbackId] = responseCallback;
      message.callbackId = callbackId;
    }

    const messageString = this.stringifyMessage(message);
    window.WebViewBridge.send(messageString);
  }

  private parseToJSON(messageString: string | object) {
    let message: Partial<Message> = {};

    if (typeof messageString === "string") {
      try {
        message = JSON.parse(messageString);
      } catch (err) {
        message = {};
      }
    } else {
      message = messageString;
    }

    return message;
  }

  private stringifyMessage(message: {}) {
    let messageString = "";

    try {
      messageString = JSON.stringify(message);
    } catch (err) {
      if (err instanceof Error) {
        this.onError(err);
      }
    }

    return messageString;
  }

  private onError(error: Error | string) {
    console.error("got error from webview bridge: ", error); // eslint-disable-line no-console
  }
}
