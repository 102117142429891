import * as constants from "./actionTypes";
import {
  Contact,
  SetButtonsState,
  SetPrivateMenuState,
  SetPrivateMenuSwipeState,
  SetActiveMessageState,
  SetDeletedMessageState,
  AddOfficials,
  SetUserLocaleToStore,
  ToggleAlternativeTitle,
  SetUserId,
  UserData,
  SetMessagesConfig
} from "../interfaceTypes";
import { AlertListElement, Property, Association, Config, House, Permission } from "src/typings/interfaceTypes";
import { MessageType } from "src/typings/types";
import { storeLocal } from "src/services/handleLocalStorage";
import { ViewNames } from "src/constants/globalConstants";

export const setButtonsState = (state: boolean): SetButtonsState => {
  return {
    type: constants.TOGGLE_BUTTONS_DISABLED,
    payload: {
      state
    }
  };
};

export const setUserId = (id: string): SetUserId => {
  return {
    type: constants.ADD_USER_ID,
    payload: {
      id
    }
  };
};

export const updateUserStore = (user: UserData) => {
  return {
    type: constants.ADD_USER_DATA,
    payload: {
      id: user.id,
      name: user.name,
      email: user.email,
      terms_accepted: user.terms_accepted
    }
  };
};

export const addAlertElement = (alertElement: AlertListElement): any => {
  return {
    type: constants.ADD_ALERT_ITEM,
    payload: {
      alertElement
    }
  };
};

export const removeAlertElement = (alertId: number): any => {
  return {
    type: constants.REMOVE_ALERT_ITEM,
    payload: {
      alertId
    }
  };
};

export const setPrivateMenuState = (state: boolean): SetPrivateMenuState => {
  return {
    type: constants.TOGGLE_PRIVATE_MENU_OPEN,
    payload: {
      state
    }
  };
};

export const setPrivateMenuSwipeState = (state: boolean): SetPrivateMenuSwipeState => {
  return {
    type: constants.SWIPE_PRIVATE_MENU_OPEN,
    payload: {
      state
    }
  };
};

export const setUserLocaleToStore = (state: string): SetUserLocaleToStore => {
  return {
    type: constants.SET_USER_LOCALE,
    payload: {
      state
    }
  };
};

export const setActiveMessage = (messageId: number, messageType: MessageType): SetActiveMessageState => {
  return {
    type: constants.ADD_ACTIVE_MESSAGE,
    payload: {
      id: messageId,
      message_type: messageType
    }
  };
};

export const setDeletedMessageId = (messageId: number): SetDeletedMessageState => {
  return {
    type: constants.ADD_DELETED_MESSAGE_ID,
    payload: {
      deletedMessageId: messageId
    }
  };
};

export const setShowArchivedMessages = (showArchivedMessages: boolean) => {
  return {
    type: constants.SET_SHOW_ARCHIVED_MESSAGES,
    payload: {
      showArchivedMessages
    }
  };
};

export const addOfficials = (officials: Contact[]): AddOfficials => {
  return {
    type: constants.ADD_OFFICIALS,
    payload: {
      officials
    }
  };
};

export const toggleAlternativeTitleVisible = (state: boolean): ToggleAlternativeTitle => {
  return {
    type: constants.TOGGLE_ALTERNATIVE_TITLE_VISIBLE,
    payload: {
      state
    }
  };
};

export const setAlternativeTitle = (state: string): SetPrivateMenuState => {
  return {
    type: constants.SET_ALTERNATIVE_TITLE,
    payload: {
      state
    }
  };
};

export const setUserObjects = (objects: Property[] | []): any => {
  return {
    type: constants.ADD_USER_OBJECTS,
    payload: {
      objects
    }
  };
};

export const setActiveAssociation = (association: Association): any => {
  return {
    type: constants.SET_ACTIVE_ASSOCIATION,
    payload: {
      state: association
    }
  };
};

export const resetRedux = (): any => {
  return {
    type: constants.USER_LOGOUT
  };
};

export const setMessagesConfig = (config: Config): SetMessagesConfig => {
  return {
    type: constants.UPDATE_MESSAGES_CONFIG,
    payload: {
      config
    }
  };
};

export const setAssociationHouses = (houses: House[]) => {
  return {
    type: constants.SET_ASSOCIATION_HOUSES,
    payload: {
      state: houses
    }
  };
};

export const setActiveObject = (object: Property): any => {
  storeLocal("currentObjectId", object.id);
  storeLocal("currentObjectType", object.type);
  return {
    type: constants.SET_ACTIVE_OBJECT,
    payload: {
      activeOject: object
    }
  };
};

export const handleMessageOpen = (isOpen: boolean): any => {
  return {
    type: constants.HANDLE_MESSAGE_OPEN,
    payload: {
      isOpen
    }
  };
};

export const setUserPermissions = (permissions: Permission): any => {
  return {
    type: constants.ADD_USER_PERMISSIONS,
    payload: {
      permissions
    }
  };
};

export const setCurrentView = (view: ViewNames): any => {
  return {
    type: constants.SET_CURRENT_VIEW,
    payload: {
      currentView: view
    }
  };
};
