import { SET_CURRENT_VIEW } from "../actions/actionTypes";
import { ViewNames } from "src/constants/globalConstants";

interface InitialState {
  currentView?: ViewNames;
  previousView?: ViewNames;
}

const initialState: InitialState = {
  currentView: undefined,
  previousView: undefined
};

export default function routeReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_CURRENT_VIEW: {
      return {
        ...state,
        // On first load set the previous view to the current view
        previousView: state.currentView !== undefined ? state.currentView : action.payload.currentView,
        currentView: action.payload.currentView
      };
    }
    default:
      return state;
  }
}
