import { combineReducers } from "redux";
import { USER_LOGOUT } from "../actions/actionTypes";

import buttons from "./buttons";
import alerts from "./alerts";
import menus from "./menus";
import locale from "./locale";
import messages from "./messages";
import user from "./user";
import association from "./association";
import object from "./object";
import routes from "./routes";

const appReducer = combineReducers({ buttons, alerts, menus, messages, locale, user, association, object, routes });

export const rootReducer = (state: AppState, action: any) => {
  if (action.type === USER_LOGOUT) {
    // Reset the Redux store and init the initial state of every reducer
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export type AppState = ReturnType<typeof appReducer>;
