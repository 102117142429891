import i18n from "es2015-i18n-tag";
import { FileRejection } from "react-dropzone";
import Rollbar from "rollbar";
import { GraphQLError } from "graphql";

// Services
import { CreateAlert } from "src/services/handleAlerts";

// Typings
import { GraphQLErrorWithTokens, ErrorObject, Config } from "../typings/interfaceTypes";

// Constants
import { alertTypes } from "src/constants/alertConstants";

export default function handleErrors(
  error: GraphQLError | GraphQLErrorWithTokens | ErrorObject | string | unknown
): string {
  // Check if error object was passed here
  if (error) {
    if (typeof error === "string") {
      return i18n.translate(error);
    } else {
      const err: GraphQLError | GraphQLErrorWithTokens | ErrorObject =
        error instanceof Error &&
        "graphQLErrors" in error &&
        Array.isArray(error.graphQLErrors) &&
        error.graphQLErrors.length > 0
          ? error.graphQLErrors[0]
          : error;

      if ("tokens" in err && err.tokens) {
        const tokens = err.tokens.map(token => (typeof token === "number" ? token.toString() : token));
        return i18n.translate(err.message, ...tokens);
      } else {
        i18n.translate(err.message);
      }
    }
  }

  return "";
}

export const onAttachmentDropRejected = (e: FileRejection[], config: Config) => {
  e.forEach(rejection => {
    if (rejection.errors && rejection.errors.length > 0) {
      const errorCode = rejection.errors[0].code;

      if (errorCode === "file-too-large") {
        CreateAlert({
          alertMessage: i18n`Fail on liiga suur!`,
          body: `${i18n`Fail`} "${
            rejection.file.name
          }" ${i18n`on manusena lisamiseks liiga suur. Manuse lubatud maksimaalne suurus on`} ${
            config.max_filesize_readable
          }`,
          alertType: alertTypes.error
        });
      } else if (rejection.file.size < 1) {
        CreateAlert({
          alertType: alertTypes.error,
          alertMessage: i18n`Fail on tühi!`,
          body: `${i18n`Fail`} "${rejection.file.name}" ${i18n`ei saa olla tühi.`}`
        });
      }
    }
  });
};

/**
 *
 * @param rollbar Rollbar instance
 * @param message Error message
 * @param error Error object
 * @param samplingRate for example 0.1 means 10% of the errors will be sent to Rollbar
 */
export const sendError = (rollbar: Rollbar, message: string, error: Error, samplingRate: number) => {
  console.error(message, error);

  if (rollbar && rollbar.error && Math.random() < samplingRate) {
    rollbar.error(message, error);
  }
};
