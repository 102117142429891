import React from "react";
import { compose, ChildProps, withApollo, Query } from "react-apollo";
import { Redirect, Route, withRouter, RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { InMemoryCache } from "apollo-cache-inmemory/lib/inMemoryCache";
import { ApolloProviderProps } from "react-apollo/ApolloProvider";

// Components
import Loader from "src/components/loader/Loader";

// Queries
import viewerQuery from "../../queries/viewerQuery";

// Services
import { setUserLocale, fetchLocal } from "../../services/handleLocalStorage";
import webviewBridge from "src/services/webview-bridge";

// Store
import { setCurrentView } from "src/store/actions";

// Types
import { ViewLinks, ViewNames } from "src/constants/globalConstants";

interface QueryResponses {
  viewer?: {
    id: string;
    language: string;
  };
}

interface State {
  refetchCount: number;
}

interface ReduxProps {
  setCurrentView: (view: ViewNames) => void;
}

type OwnProps = RouteComponentProps &
  ReduxProps & {
    unauthorizedPath: string;
    component: any;
    disableQueries: boolean;
    appLang?: string;
    token?: string;
  };
type Props = ChildProps<OwnProps> & ApolloProviderProps<InMemoryCache>;

class PrivateRoute extends React.Component<Props> {
  public state: State = {
    refetchCount: 0
  };
  private unlisten: () => void;

  componentWillMount() {
    // Set the current view name on route change
    this.unlisten = this.props.history.listen((location, action) => {
      this.handleCurrentViewName(location.pathname);
    });
  }

  componentDidMount() {
    // Set the current view name on mount
    this.handleCurrentViewName(this.props.location.pathname);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleCurrentViewName(pathname: string) {
    let viewName: ViewNames | undefined;
    if (
      pathname === "/private" + ViewLinks.Messages ||
      pathname.match(/\/private\/.+\/messages\/(discussion|issue)\/.+/)
    ) {
      viewName = ViewNames.Messages;
    } else if (pathname === "/private" + ViewLinks.Readings) {
      viewName = ViewNames.Readings;
    } else if (pathname === "/private" + ViewLinks.Invoices) {
      viewName = ViewNames.Invoices;
    } else if (pathname === "/private" + ViewLinks.Association) {
      viewName = ViewNames.Association;
    } else if (pathname === "/private" + ViewLinks.AddPerson) {
      viewName = ViewNames.AddPerson;
    } else if (pathname === "/private" + ViewLinks.UpdatePassword) {
      viewName = ViewNames.UpdatePassword;
    } else if (pathname === "/private" + ViewLinks.Entrances) {
      viewName = ViewNames.Entrances;
    } else if (pathname === "/private" + ViewLinks.MessageComposer) {
      viewName = ViewNames.MessageComposer;
    } else if (pathname.startsWith("/private" + ViewLinks.Settings)) {
      viewName = ViewNames.Settings;
    }

    if (viewName) {
      this.props.setCurrentView(viewName);
    }
  }

  public render() {
    if (this.props.disableQueries) {
      return <Redirect to={"/public"} />;
    }

    if (webviewBridge.isNative() && !this.props.token) {
      return <Loader />;
    }

    return (
      <Query<QueryResponses> query={viewerQuery} fetchPolicy="network-only">
        {({ loading, data, error, refetch }) => {
          if (loading) {
            return <Loader />;
          }

          // get users selected language
          if (data && data.viewer && !error) {
            const currentLocale = fetchLocal("locale", process.env.REACT_APP_DEFAULT_LOCALE);
            if (currentLocale !== data.viewer.language && !this.props.appLang) {
              setUserLocale(data.viewer.language, this.props.client);
            }
          }
          const { component: Component, unauthorizedPath, ...rest } = this.props;

          // Check if is from webview and if query failed send logout signal
          if (webviewBridge.isNative()) {
            // Constructed this logic the same way routing logic below to mach same cases.
            if (data && data.viewer && !error) {
              // Pass
              webviewBridge.log("viewerQuery login data:", data);
            } else if (error) {
              webviewBridge.log("viewerQuery login error", error);
              webviewBridge.logout("JWT_EXPIRED");
            } else if (!data) {
              webviewBridge.log("!data", data);
              return <Loader />;
            } else if (!data.viewer && this.state.refetchCount <= 10) {
              webviewBridge.log("!data.viewer", data);
              webviewBridge.log("refetch count", this.state.refetchCount);
              // For iOS bug. If no viewer data is returned refetch the data (max 10 times)
              this.setState({ refetchCount: this.state.refetchCount + 1 });
              refetch();
              return <Loader />;
            } else {
              webviewBridge.log("viewerQuery login failed. Data: ", data);
              webviewBridge.log("viewerQuery login failed. Error: ", error);
              webviewBridge.logout("JWT_EXPIRED");
            }
          }
          return (
            <Route
              {...rest}
              render={props =>
                data && data.viewer && !error ? (
                  Component
                ) : (
                  <Redirect
                    to={{
                      pathname: unauthorizedPath,
                      state: { from: props.location }
                    }}
                  />
                )
              }
            />
          );
        }}
      </Query>
    );
  }
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({ setCurrentView }, dispatch);
}

export default compose(withApollo, withRouter)(connect(null, mapDispatchToProps)(PrivateRoute));
